import { PageProps } from "gatsby";
import * as React from "react";
import { useAuth } from "auth";
import { Sidebar, SidebarLink, SidebarDivider } from "components/layout";

export function AdminSidebar(props: PageProps) {
  const { access } = useAuth();

  return (
    <Sidebar>
      <SidebarLink
        to={`/administration`}
        active={props.location.hash.includes(`/administration`)}
        currentPath={props.location.pathname}
      >
        API Keys
      </SidebarLink>
      <SidebarLink
        to={`/administration/newkey`}
        active={props.location.hash.includes(`/administration/newkey`)}
        currentPath={props.location.pathname}
      >
        New Key
      </SidebarLink>
      <SidebarLink
        to={`/administration/builder`}
        active={props.location.hash.includes(`/administration/builder`)}
        currentPath={props.location.pathname}
      >
        Map Builder
      </SidebarLink>
      <SidebarLink
        to={`/administration/maps`}
        active={props.location.hash.includes(`/administration/maps`)}
        currentPath={props.location.pathname}
      >
        My Maps
      </SidebarLink>
      {access?.admin && (
        <>
          <SidebarDivider title="Admin Links">
            <SidebarLink
              to={`/administration/users`}
              active={props.location.hash.includes(`/administration/users`)}
              currentPath={props.location.pathname}
            >
              Users
            </SidebarLink>
            <SidebarLink
              to={`/administration/messages`}
              active={props.location.hash.includes(`/administration/messages`)}
              currentPath={props.location.pathname}
            >
              Messages
            </SidebarLink>
          </SidebarDivider>
        </>
      )}
    </Sidebar>
  );
}
