import * as React from "react";
import { copyTextToClipboard } from "store/utils";
import { ClipboardIcon, EyeCloseIcon, EyeOpenIcon, TrashIcon } from "components/Icons";
import {
  row,
  actionButton,
  buttonsContainer,
  apikeyText,
  popover,
  show,
  hide,
  noFill
} from "./APIKeyRow.module.scss";
import { useKey } from "hooks/api";
import { className } from "utils/className";

export const APIKeyRow = ({ apikey }: { apikey: string }) => {
  const [showAPIKey, setShowAPIKey] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const { removeKey } = useKey(apikey || '');

  const handleClick = React.useCallback(() => {
    copyTextToClipboard(apikey || "").then((success) => {
      if (success) {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 750);
      }
    });
  }, []);

  const handleConfirmDelete = React.useCallback(
    (e) => {
      e.stopPropagation();
      const confirmed = confirm("Are you sure you want to delete this key?");
      if (confirmed) removeKey()
    }, [removeKey]);

  return (
    <div className={row}>
      <span className={apikeyText}>
        {showAPIKey ? (
          <span onClick={handleClick}>
            {apikey}
          </span>
        ) : (
          "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
        )}
      </span>
      <div className={buttonsContainer}>
        <button
          className={className(actionButton, noFill)}
          aria-label={showAPIKey ? "Hide API Key" : "Show API Key"}
          onClick={() => setShowAPIKey(!showAPIKey)}
        >
          {showAPIKey ? <EyeCloseIcon /> : <EyeOpenIcon />}
        </button>
        <button
          className={className(actionButton, noFill)}
          aria-label="Copy to clipboard"
          onClick={() => handleClick()}
        >
          {<ClipboardIcon />}
          <span className={`${popover} ${copied ? show : hide}`}>Copied!</span>
        </button>
        <button
          className={actionButton}
          aria-label="Delete map"
          onClick={handleConfirmDelete}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
