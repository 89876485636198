import * as React from "react";
import { SortHeader } from "components/common/Table/SortHeader";
import { table } from "./Table.module.scss";

export interface TableProps<T> extends React.PropsWithChildren<{}> {
  data?: T[];
  columns: Array<{
    accesor: keyof T | ((row: T) => string);
    title: string;
    render?: (value: string | T[keyof T], row: T) => React.ReactNode;
    sort?: Function;
    width?: string;
  }>
  onRowClick: (id: string) => void;
}

export function Table<T extends { id: string }>(props: TableProps<T>) {
  const { data, columns, onRowClick } = props;

  return (
    <table className={table}>
      <thead>
        <tr>
          {columns.map((column, i) => (
            <th
              key={column.title}
              style={column.width ? { width: column.width } : {}}
            >
              {column.sort ? (
                <SortHeader
                  title={column.title}
                  onClick={(e: boolean) => {
                    columns[i]?.sort && columns[i].sort?.(e);
                  }}
                />
              ) : (
                column.title
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, i) => (
          <tr key={i} onClick={() => onRowClick?.(row.id)}>
            {columns.map((column) => {
              const value =
                typeof column.accesor === "function"
                  ? column.accesor(row)
                  : row[column.accesor];
              return (
                <td key={column.title}>
                  {column.render ? column.render(value, row) : value}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      {props.children}
    </table >
  );
}

Table.defaultProps = {
  pageLength: 10,
};
